@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;500;600&family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600&family=Rajdhani:wght@400;500;600&display=swap');

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  /* background-color: rgba(26, 27, 39, 1); */

}

section>* {
  padding: 0 !important;
}

body {
  margin: 0;
  /* font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  color: #fff;
  margin: 0;
  font-size: 0.875rem;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 1.43;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.side-layout {
  background-image: "https://www.s5.com/images/background.jpg";
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 4;

}

:where(.css-dev-only-do-not-override-xu9wm8).ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: rgba(34, 36, 51, 1);
  transition: all 0.2s, background 0s;
  /* z-index: ; */
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark,
:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark>.ant-menu {
  color: rgba(255, 255, 255, 0.65);
  background: rgba(34, 36, 51, 1);

}

:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-xu9wm8).ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: rgba(34, 36, 51, 1);
}

.sidebar-menu {
  /* height: 100%; */
  font-size: 16px;
  gap: 22px;
}

.sidebar-menu ul li span {
  font-size: 16px;

}

.sidebar-menu ul li .ant-menu-item {
  margin: 1 0px 0 !important;
}

.ant-menu-inline .ant-menu-item {
  margin: 10px 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}