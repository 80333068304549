.place-text::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.header-btn-right .cta {
    display: flex;
}

.header-btn-right .btn-signin {
    width: 90px;
}

.main-header {
    display: block;
}


.header-btn-login {
    display: block;
}

.header-btn-right-mobile {
    display: none;
    z-index: 999;
    position: relative;
}

.menu-mobile {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .menu-mobile {
        display: none;
    }
}


@media only screen and (max-width: 768px) {

    .header-btn-right-mobile {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        flex-shrink: 1;
        z-index: 1100;
        backdrop-filter: blur(10px);
        /* background-color: rgba(26, 27, 39, 0.75) !important; */
    }

    .header-btn-right-mobile .cta {
        display: flex;
        width: 100%;
    }

    .header-btn-right-mobile .cta .btn-div-login {
        width: 50%;
        margin: 0 8px;
    }

    .header-btn-right-mobile .cta .btn-div-sign {
        width: 50%;
        margin: 0 8px;
    }

    .header-btn-right-mobile .cta .btn-div-login .btn-login {
        width: 100%;

    }

    .header-btn-right-mobile .cta .btn-div-sign .btn-signin {
        width: 100%;

    }

    .header-btn-right .header-btn-login,
    .header-btn-right .btn-signin {
        max-width: 100%;
    }

    .header-btn-login .btn-div-login {
        width: 100%;
    }

    .header-btn-right .btn-signin {
        width: 100%;
    }

    /* sidebar */
    .sidebar-container {
        display: none;
    }

    .sidebar-btn {
        display: none !important;
    }

    /* sidebar end */

    .main-header {
        display: none;
    }

    .header-btn-right {
        display: block;
    }

    .menu-mobile {
        display: block;
    }
}