.promotion__container .icon-left,
.icon-left {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 5px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 8px;
    background-color: rgba(26, 27, 39, 1);
    cursor: pointer;
    margin-left: 10px;
}

.promotion__container .card-container {
    height: 100% !important;
}

.promotion__container .card.card-promotion {
    width: 100%;
    height: 100% !important;
    z-index: 1;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    background-color: rgba(34, 36, 51, 1);
    color: #fff;
}

.promotion__container .card-promotion .custom-card-title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
}

.promotion__container .card-promotion .custom-card-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgba(255, 255, 255, 0.5);
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.gamelist-section .view-all {
    font-size: 1rem;
    min-width: 32px;
    font-weight: 600;
    line-height: 1.5rem;
    text-transform: capitalize;

    border: 1px solid rgba(255, 255, 255, 0.23);
    padding: 5px 15px;
    border-radius: 8px;
    margin: 0;
}




.sticky-slider .slick-list .slick-track .card-lv {
    height: auto;
    /* display: none; */
    /* css-float: left; */
    min-height: 1px;
    transition: width 300ms ease, opacity 1000ms ease 0s, visibility 1000ms ease 0s !important;
}


/* image hover live dealers */
.middle-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle-hover-effect {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-content: center;
    max-height: 100%;
}

.image-container {
    position: relative;
}

.middle-hover-effect .text {
    max-width: fit-content;
}

.image-container:hover .middle-image {
    opacity: 0.3;
    cursor: pointer;
}

.image-container:hover .middle-hover-effect {
    opacity: 1;
    cursor: pointer;
}


.btn-navlink {
    cursor: pointer;
    height: 30px;
    margin: 0 auto;
    padding: 0 15px;
    z-index: 3;
    /* position: absolute; */
    font-size: 10px;
    max-width: 100px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 50%);
    text-align: center;
    line-height: 30px;
    white-space: nowrap;
    border-radius: 8px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: rgba(19, 21, 33, .5);
}

/* image hover live dealers end */


/* latest */
.table-cell-head {
    background-color: rgba(26, 27, 39, 1);
    color: #fff;
    font-weight: 500;
    line-height: 1.5rem;
    padding: 16px;
    font-size: 0.875rem;
    text-align: left;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    vertical-align: inherit;
}

table {
    background-color: none;
    border: none;
}


.table-rounded thead th:first-child {
    border-radius: 10px 0 0 0;
    background-color: none;
}

.table-rounded thead th:last-child {
    border-radius: 0 10px 0 0;
    border: none;
    background-color: none;
}


.table-rounded tbody td {
    display: table-cell;
    padding: 16px;
    font-size: 0.875rem;
    text-align: left;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 1.43;
    background-color: rgb(34,36,51);
    color: #fff;
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    vertical-align: inherit;
}
.table-rounded tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
    /* background-color: none; */

}

.table-rounded tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
    background-color: none;

}
/* latest end */




@media screen and (max-width: 768px) {}